import { ButtonTypeEnum } from '../entities/messages';
import { ChannelCTATypeEnum, WorkflowTypeEnum } from '../types';
export const isBridgeWorkflow = (workflowType) => {
    return workflowType === WorkflowTypeEnum.BRIDGE || workflowType === WorkflowTypeEnum.ECHO;
};
export const inAppMessageFromBridgeOutputs = (outputs) => {
    var _a, _b, _c, _d, _e, _f;
    const cta = {
        type: ChannelCTATypeEnum.REDIRECT,
        data: {
            url: (_a = outputs === null || outputs === void 0 ? void 0 : outputs.redirect) === null || _a === void 0 ? void 0 : _a.url,
            target: (_b = outputs === null || outputs === void 0 ? void 0 : outputs.redirect) === null || _b === void 0 ? void 0 : _b.target,
        },
        action: {
            result: {},
            buttons: [
                ...((outputs === null || outputs === void 0 ? void 0 : outputs.primaryAction)
                    ? [
                        {
                            type: ButtonTypeEnum.PRIMARY,
                            content: outputs.primaryAction.label,
                            url: (_c = outputs.primaryAction.redirect) === null || _c === void 0 ? void 0 : _c.url,
                            target: (_d = outputs === null || outputs === void 0 ? void 0 : outputs.primaryAction.redirect) === null || _d === void 0 ? void 0 : _d.target,
                        },
                    ]
                    : []),
                ...((outputs === null || outputs === void 0 ? void 0 : outputs.secondaryAction)
                    ? [
                        {
                            type: ButtonTypeEnum.SECONDARY,
                            content: outputs.secondaryAction.label,
                            url: (_e = outputs.secondaryAction.redirect) === null || _e === void 0 ? void 0 : _e.url,
                            target: (_f = outputs === null || outputs === void 0 ? void 0 : outputs.secondaryAction.redirect) === null || _f === void 0 ? void 0 : _f.target,
                        },
                    ]
                    : []),
            ],
        },
    };
    return {
        subject: outputs === null || outputs === void 0 ? void 0 : outputs.subject,
        content: (outputs === null || outputs === void 0 ? void 0 : outputs.body) || '',
        cta,
        avatar: outputs === null || outputs === void 0 ? void 0 : outputs.avatar,
        data: outputs === null || outputs === void 0 ? void 0 : outputs.data,
    };
};
